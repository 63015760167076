<style lang="less">
    @import "../styles/media";
    
    #chatra.chatra--pos-middle:not(.chatra--expanded) {
        bottom: 100px !important;
    }

    #chatra.chatra--side-right {
        right: 30px !important;
    }
    
    @media @mobile-alt {
        #chatra.chatra--pos-middle:not(.chatra--expanded) {
            bottom: 50px !important;
            left: 20px !important;
        }

        #chatra.chatra--side-right {
            right: auto !important;
            left: 0;
        }
    }
</style>

<template>

    <div id="chatra-wrapper"></div>

</template>

<script>

    export default {
        created() {
            const chatraId = '4ycwAFKGfd8TSoSio';
            
            window.ChatraSetup = {
                zIndex: 800,
                injectTo: 'chatra-wrapper',
                buttonSize: 50,
                colors: {
                    buttonText: '#5BC96D', /* chat button text color */
                    buttonBg: '#ffffff'    /* chat button background color */
                }
            };

            
            (function(d, w, c) {
                w.ChatraID = chatraId;
                var s = d.createElement('script');
                w[c] = w[c] || function() {
                    (w[c].q = w[c].q || []).push(arguments);
                };
                s.async = true;
                s.src = 'https://call.chatra.io/chatra.js';
                if (d.head) d.head.appendChild(s);
            })(document, window, 'Chatra');
        }

    };
</script>
