<style scoped lang="less">
    @import './../styles/variables';

    #header {
        background: white;
        padding: 0;
        width: 100%;
        z-index: 100;
        top: 0;
        display: flex;
        align-items: center;
        position: relative;

        .logo {
            position: relative;
            margin-right: 30px;
            padding-right: 30px;
            display: flex;
            align-items: center;
            font-size: 26px;
            
            img {
                width: 40px;
                height: 40px;
                margin: 0 20px 0 30px;
            }

            span {
                display: inline-block;
            }
        }

        .spacer {
            flex: 1;
        }

        .language-select-container {
            line-height: 60px;
        }

        .language-select {
            padding: 0 20px;

            &:hover {
                background: @border-color-split;
            }
        }

        .account {
            padding: 0 30px;
            align-self: flex-end;
            line-height: 32px;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;

            &:hover {
                background: @border-color-split;
            }
        }
        
        &.logo {
            justify-content: center;
            border-bottom: 0 solid @border-color-base;
            
            .logo {
                margin: 0;
                padding: 0;
                border: 0;
                
                img {
                    margin-left: 0;
                }
            }
        }

        @media @mobile {
            height: @layout-header-height-m;
            line-height: @layout-header-height-m;
            
            .logo {
                margin-right: 15px;
                padding-right: 15px;
                font-size: 22px;

                img {
                    width: 30px;
                    height: 30px;
                    margin: 0 10px;
                }
            }
            
            .account,
            .language-select {
                display: none;
            }
            
            .spacer {
                flex: 0;
            }
        }
    }
</style>

<template>

    <Header id="header" :class="type">
        <router-link class="logo heading-display" tag="a" :to="{ name: 'home' }">
            <img v-lazy="'/images/logo.svg'" alt="Logo"> <span class="heading">{{ projectName }}</span>
        </router-link>

        
        <CitySelect v-if="type === 'full'"></CitySelect>
        
        <span v-if="type === 'full'" class="spacer"></span>

        <div class="language-select-container">
            <LanguageSelect v-if="type === 'full'"></LanguageSelect>
        </div>

        <router-link v-if="user && type === 'full'" class="account" :to="{ name: 'account' }">
            <Avatar>{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0) }}</Avatar>
        </router-link>
        
        <div v-else-if="type === 'full'" @click="login" class="account" style="line-height: 20px;">
            <span style="display: flex;">{{ $t('main.login') }} <Icon size="20" type="md-log-in" style="padding-left: 5px" /></span>
        </div>
    </Header>

</template>

<script>
    import Vue from 'vue';
    import config from './../config';
    import Flag from './flag';
    import CitySelect from './citySelect';
    import LanguageSelect from './languageSelect';
    import { mapGetters, mapState } from 'vuex';

    Vue.component('Flag', Flag);
    Vue.component('CitySelect', CitySelect);
    Vue.component('LanguageSelect', LanguageSelect);

    export default {
        props: {
            type: {
                type: String,
                default: 'full'
            }
        },        
        data: function () {
            return {
                projectName: config.projectName,
            }
        },
        computed:
            Object.assign(
                {},
                mapState(['user','cities'])
            ),
        methods: {
            login() {
                this.$router.push({
                    name: 'login'
                })
            }
        }
    };
</script>
