<style scoped lang="less">
    .language-select {
        cursor: pointer;
    }

</style>

<template>
    
    <Dropdown v-if="locale && locales" class="language-select">
        <Flag :locale="locale"></Flag> {{ language[locale] }} <Icon type="ios-arrow-down"></Icon>
        
        <DropdownMenu slot="list">
            <DropdownItem v-for="(name, key) in language" :name="name" :key="key">
                <div @click="saveLocale(key)" style="font-size: 16px">
                    <Flag :locale="key"></Flag> {{ language[key] }}
                </div>
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
    
</template>

<script>
    import Vue from 'vue';
    import Flag from './flag';
    import { mapActions, mapGetters } from 'vuex';
    
    Vue.component('Flag', Flag);

    export default {
        data: function () {
            return {
                language: {
                    'de-DE': 'Deutsch',
                    'en-GB': 'English',
                    'fr-FR': 'Français',
                    'vi-VN': 'Tiếng Việt'
                }
            }
        },
        methods: Object.assign({
                saveLocale(language) {
                    this.setLocale(language);
                    this.$root.$i18n.locale = language;
                }},
            mapActions([
                'setLocale'
            ])),
        computed: 
            Object.assign({
                    locale() {
                        return this.$store.state.locale;
                    },
                }, 
            mapGetters(['locales']))
    };
</script>
