<template>
    <article>
        <router-view />
        <Loader />
        <Chatra />
    </article>
</template>

<script>
    import Vue from 'vue';
    import Loader from './components/loader';
    import Chatra from './components/chatra';
    import { mapActions } from 'vuex';
    import config from './config';

    Vue.component('Loader', Loader);
    Vue.component('Chatra', Chatra);

    export default {
        metaInfo: {
            htmlAttrs: {
                lang: 'en',
            },
            title: 'The easy way to find your next apartment in Vietnam',
            titleTemplate: `%s | ${config.projectName}`,
            meta: [
                { name: 'description', content: 'Find your new apartment in Vietnam in no time by letting our search engine do the legwork for you.' },
                { charset: 'utf-8' }
            ]
        },
        data () {
            return {}
        },
        created () {
            this.fetchCities();
            this.fetchUser();
        },
        beforeDestroy () {

        },
        methods: mapActions([
            'fetchUser',
            'fetchCities'
        ])
    }
</script>
