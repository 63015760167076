<style scoped lang="less">
    @import './../styles/variables';
    @import './../styles/animations';

    .details {
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh;
        height: 100%;
        padding: 40px 10px;
        background: rgba(255, 255, 255, 0.8);
        overflow: auto;
        animation-duration: @default-animation-duration;
        animation-fill-mode: both;

        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        
        &.create {
            animation-name: fadeIn;
            
            article {
                animation-name: slideInUp;
            }
        }
        
        &.destroy {
            animation-name: fadeOut;

            article {
                animation-name: slideOutDown;
            }
        }

        .close {
            position: fixed;
            right: 30px;
            top: 30px;
            z-index: 1003;
        }

        .go-to {
            position: fixed;
            z-index: 1003;
            top: calc(~"100% - 45px");
            left: 50vw;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 400px;
            padding: 15px;
            font-size: 16px;
            font-weight: 800;
            box-shadow: @shadow-3dp;
            text-shadow: 0 0 2px rgba(43,105,56,.52);
        }

        .ivu-tag-default {
            background: #fff;
        }

        h1, h2 {
            color: @text-color;
        }
        
        @media @mobile {
            padding: 60px 10px 100px;
            
            .close {
                top: 10px;
                right: 10px
            }

            .go-to {
                max-width: 300px;
            }
        }
    }
    
    article {
        overflow: hidden;
        background: white;
        border-radius: @border-radius-base;
        width: 100%;
        position: relative;
        z-index: 1002;
        max-width: 800px;
        margin: 0 auto;
        box-shadow: @main-box-shadow;
        animation-duration: @default-animation-duration;
        animation-fill-mode: both;

        .time {
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 2;
            padding: 5px 15px;
            border-radius: 22px;
            background: #e6ebf1;
            color: #808695;
            font-size: 14px;
            box-shadow: @shadow-2dp;
            margin-right: 10px;
        }        

        .img-grid {
            height: 350px;
            width: 100%;
            display: flex;
            flex-direction: row;
            position: relative;
            
            div {
                min-height: 0;
                display: flex;
                flex: 1;
                position: relative;
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
                background-color: @border-color-base;

                [lazy=loading] {
                    background-size: 40px !important;
                }
                
                &.last {
                    
                    &:before {
                        position: absolute;
                        content: '';
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: fade(black, 60%);
                        z-index: 1;
                        pointer-events: none;
                    }
                    
                    span {
                        z-index: 2;
                        position: absolute;
                        display: block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        color: white;
                        padding: 10px;
                        pointer-events: none;
                        line-height: 20px;
                        width: 100%;
                        
                        
                        i {
                            font-size: 24px;
                            opacity: 0.5;
                        }
                        
                        b {
                            padding-top: 10px;
                            font-weight: lighter;
                            display: block;
                            font-size: 16px;
                        }
                    }
                    
                    &:hover span {
                        color: @link-color;
                    }
                }
            }
            
            .img {
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: fade(black, 0%);
                    z-index: 0;
                    transition: all 250ms ease-out;
                    pointer-events: none;
                }

                &:hover:before {
                    background: fade(@primary-color, 30%);
                }
            }
            
            // img Grid
            > div {
                @spacing: 2px;
                position: relative;
                
                &:first-child {
                    border-right: @spacing solid white;
                }
                
                &:last-child {
                    border-left: @spacing solid white;
                    flex-direction: column;
                    
                    > div {
                        &:first-child {
                            border-bottom: @spacing solid white;
                        }

                        &:last-child {
                            border-top: @spacing solid white;
                            
                            > div {
                                &:first-child {
                                    border-right: @spacing solid white;
                                }

                                &:last-child {
                                    border-left: @spacing solid white;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .body {
            padding: 0 20px;
            
            h1 {
                line-height: 30px;
            }
            
            .provider {
                font-size: 14px;
                color: @subsidiary-color;
            }
            
            p {
                margin-bottom: 30px;
            }
            
            .key-feats {
                display: flex;
                list-style: none;
                padding: 30px 0;
                justify-content: center;
                flex: 1;
                align-items: center;
                
                li {
                    margin-right: 40px;
                    
                    img {
                        margin-left: 10px;
                        width: 38px;
                        height: 38px;
                    }

                    &.empty {
                        opacity: .35;
                    }
                    
                    :last-of-type {
                        margin-right: 0;
                    }
                    
                    span {
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row;
                        line-height: 34px;
                        font-size: 14px;

                        b {
                            font-size: 32px;
                        }
                    }
                }
            }
            
            .ivu-tag {
                height: 40px;
                line-height: 40px;
                margin: 5px 5px 0 0;
                padding: 0 24px;
                border-radius: @border-radius-base;
                font-weight: 600;
            }
        }
        
        .map {
            width: 100%;
            height: 250px;
        }
        
        @media @mobile {
            .time {
                right: auto;
                left: 20px;
                font-size: 12px;
            }
            
            .img-grid {
                height: 160px;

                div.last {
                    &:before, b, span {
                        display: none;
                    }
                }

                // img Grid
                > div {
                    @spacing: 1px;

                    &:first-child {
                        border-right: @spacing solid white;
                    }

                    &:last-child {
                        border-left: @spacing solid white;

                        > div {
                            &:first-child {
                                border-bottom: @spacing solid white;
                            }

                            &:last-child {
                                border-top: @spacing solid white;

                                > div {
                                    &:first-child {
                                        border-right: @spacing solid white;
                                    }

                                    &:last-child {
                                        border-left: @spacing solid white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            .body {
                h1 {
                    font-size: 22px;
                }
                
                h2 {
                    font-size: 16px;
                    margin-bottom: 10px !important;
                }
                
                p {
                    font-size: 14px;
                }

                .provider {
                    font-size: 12px;
                }
                
                .ivu-tag {
                    height: 35px;
                    line-height: 35px;
                    padding: 0 15px;
                }

                .key-feats {
                    padding: 20px;
                    
                    li {
                        margin-right: 20px;
                        
                        span {
                            font-size: 12px;
                            line-height: 25px;
                            
                            b {
                                font-size: 20px;
                            }
                        }

                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }

</style>

<template>

    <div id="details" class="details create" v-scroll-stop data-closable @click="closeDetails">
        
        <Button @click="closeDetails" data-closable class="close" type="primary" icon="md-close" shape="circle"></Button>

        <article>
            
            <span class="time">
                <PastTime :createdAt="this.listing.createdAt" />
            </span>

            <div class="img-grid" v-if="listing.images && listing.images.length > 3">
                <div class="img" @click="clickImage(0)" style="flex: 4" v-lazy:background-image="listing.images[0].url"></div>
                <div style="flex: 2">
                    <div class="img" @click="clickImage(1)" style="flex: 3" v-lazy:background-image="listing.images[1].url"></div>
                    <div style="flex: 2">
                        <div class="img" @click="clickImage(2)" v-lazy:background-image="listing.images[2].url"></div>
                        <div class="img last" @click="clickImage(3)" v-lazy:background-image="listing.images[3].url">
                            
                            <span>
                                <Icon type="md-albums" />
                                <b>{{ $t('details.all_pictures', { x: listing.images.length }) }}</b>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="img-grid" v-else-if="listing.images && listing.images.length > 2">
                <span class="time">
                    <PastTime :createdAt="this.listing.createdAt" />
                </span>
                <div class="img" @click="clickImage(0)" style="flex: 3" v-lazy:background-image="listing.images[0].url"></div>
                <div style="flex: 2">
                    <div class="img" @click="clickImage(1)" style="flex: 3" v-lazy:background-image="listing.images[1].url"></div>
                    <div class="img" @click="clickImage(2)" style="flex: 2">
                        <div v-lazy:background-image="listing.images[2].url"></div>
                    </div>
                </div>
            </div>

            <div class="img-grid" v-else-if="listing.images && listing.images.length > 0">
                <span class="time">
                    <PastTime :createdAt="this.listing.createdAt" />
                </span>
                <div class="img" @click="clickImage(0)" v-lazy:background-image="listing.images[0].url"></div>
            </div>

            <div class="body">
                <h1 v-if="listing" style="margin: 20px 0 0 0;">{{ listing.name }}</h1>
                <span class="provider" v-if="listing">{{ listing.provider.name }}</span>
                
                <ul class="key-feats">
                    <li :class="listing.rooms ? 'key-data' : 'key-data empty' ">
                        <span><b>{{ listing.rooms || '?' }}</b> <img class="icon-rooms" v-lazy="'/images/icons/rooms.svg'" alt="Rooms"></span>
                        <span class="truncate">{{ this.$tc('listing.room', listing.rooms) }}</span>
                    </li>
                    <li :class="listing.size ? 'key-data' : 'key-data empty' ">
                        <span><b>{{ listing.size || '?' }}</b> <img class="icon-size" v-lazy="'/images/icons/size.svg'" alt="Rooms"></span>
                        <span class="truncate">{{ this.$tc('listing.sqm', listing.size) }}</span>
                    </li>
                    <li :class="listing.price ? 'key-data' : 'key-data empty' ">
                        <span><b>$ {{ listing.price || '?' }}</b></span>
                        <span class="truncate">{{ this.$t('listing.rent') }}</span>
                    </li>
                </ul>

                <h2 v-if="listing.features && listing.features.length">{{ $t('details.heading_features') }}</h2>
                <ul class="features" style="margin-bottom: 30px;" v-if="listing.features && listing.features.length">
                    <li style="display: inline-block" v-for="feature of listing.features">
                        <Tag color="default">{{ feature }}</Tag>
                    </li>
                </ul>

                <h2 v-if="listing.description">{{ $t('details.heading_description') }}</h2>
                <p v-if="listing.description">
                    {{ listing.description }}
                </p>

                <h2 v-if="listing.location">{{ $t('details.heading_location') }}</h2> 
            </div>

            <GmapMap v-if="listing.location && listing.location.lat && listing.location.lng"
                     ref="mapDetailsRef"
                     class="map" 
                     :options="mapOptions"
                     :center="{ lat: parseFloat(listing.location.lat), lng: parseFloat(listing.location.lng) }">

                <GmapMarker :position="{ lat: parseFloat(listing.location.lat), lng: parseFloat(listing.location.lng)}"/>

            </GmapMap>

        </article>

        <Button @click="visitProviderExpose" class="go-to" type="primary">{{ $t('details.website_button') }} <Icon type="ios-link"></Icon></Button>

    </div>
    
</template>

<script>
    import Vue from 'vue';
    import config from './../config';
    import i18n from "./../i18n/i18n";
    import { slice } from "lodash";
    import PastTime from './../components/pastTime';
    import Gallery from './../components/gallery';

    Vue.component('PastTime', PastTime);

    export default {
        props: {
            listing: {
                type: Object,
                required: true
            },
        },
        computed: {
            otherImages(index) {
                return slice(this.listing.images, 4, this.listing.images.length);
            }
        },
        methods: {
            closeDetails(e) {
                e.preventDefault();
                e.stopPropagation();
                
                if (e.target.hasAttribute("data-closable") || e.target.classList.contains('ivu-icon-md-close')) {
                    this.$el.classList.add('destroy');
                    
                    setTimeout(function() {
                        // destroy the vue listeners, etc
                        this.$destroy();

                        // remove the element from the DOM
                        document.body.removeChild(this.$el);
                    }.bind(this), 400);
                }
            },
            visitProviderExpose() {
                
                let redirectUrl = null;

                if (this.listing.link.startsWith('http')) {
                    redirectUrl = this.listing.link;
                }
                else if (this.listing.link.startsWith('//')) {
                    redirectUrl = `http:${this.listing.link}`;
                }
                else if (this.listing.link.startsWith('/')) {
                    redirectUrl = `${this.listing.provider.baseUrl}${this.listing.link}`;
                }
                else if (!this.listing.link.startsWith('/')) {
                    redirectUrl = `${this.listing.provider.baseUrl}/${this.listing.link}`;
                }

                Object.assign(document.createElement('a'), { target: '_blank', href: redirectUrl}).click();
            },
            clickImage(initialSlide) {
                var GalleryClass = Vue.extend(Gallery);

                var instance = new GalleryClass({
                    propsData: {
                        images: this.listing.images,
                        initialSlide: initialSlide || 0
                    },
                    i18n
                });

                instance.$mount();
                document.body.appendChild(instance.$el)
            }
        },
        data() {
            return {
                mapOptions: config.googleMapsOptions
            }
        },
        mounted() {
            setTimeout(function () {
                if (this.listing && this.listing.location && this.listing.location.lat && this.listing.location.lng) {
                    this.$refs.mapDetailsRef.$mapPromise.then((map) => {
                        map.setZoom(15);
                        map.setCenter(new google.maps.LatLng(
                            this.listing.location.lat, 
                            this.listing.location.lng));
                    });
                }
            }.bind(this), 250);
        },
        created () {
            setTimeout(function() {
                this.$el.classList.remove('create');
            }.bind(this), 400);
        }
    };
</script>
