const config = {
    projectName: 'Taybalo',
    localStorageKey: 'taybalo',
    projectSupportMail: 'support@taybalo.co',
    apiUrl: 'https://taybalo.io/api',
    googleAnalyticsKey: 'UA-149373073-1',
    googleMapsApiKey: 'AIzaSyDzhBfKjI9v0EKlPCA06Kgytp2lT0pALXE',
    googleMapsOptions: {
        options: {
            center: {lat: 51.95800023, lng: 7.61970188},
            zoom: 12,
                gestureHandling: 'greedy',
                clickableIcons: false,
                scrollwheel: true,
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControlOptions: {
                    position: 3
                },   
                styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        },
                        {
                            "gamma": "1"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#b4d4e1"
                        }
                    ]
                }
            ]
        }
    }
};
export default config;
