import Vue from 'vue';
import Vuex from 'vuex';
import config from './config';
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
    key: config.localStorageKey,
    storage: window.localStorage,
    reducer: (state) => ({
        token: state.token,
        locale: state.locale
    }),
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        locale: 'en-GB',
        locales: ['de-DE', 'en-GB', 'vi-VN'],
        isLoading: false,
        cities: [],
        token: null,
        user: null
    },
    mutations: {
        SET_LOADING_STATE (state, status) {
            state.isLoading = status;
        },
        SET_CITIES (state, cities) {
            state.cities = cities;
        },
        SET_LOCALE (state, locale) {
            state.locale = locale;
        },
        SET_TOKEN (state, token) {
            state.token = token;
        },
        CLEAR_TOKEN (state) {
            state.user = null;
            state.token = null;
        },
        SET_USER (state, user) {
            state.user = user;
        }
    },
    getters: {
        city: (state) => (permalink) => {
            return state.cities.find(city => city._id === permalink);
        },
        cities: (state) => {
            return state.cities;
        },
        locale: (state) => {
            return state.locales[state.locale];
        },
        locales: (state) => {
            return state.locales;
        },
        token: (state) => {
            return state.token;
        }
    },
    actions: {
        fetchCities (context) {
            context.commit('SET_LOADING_STATE', true);

            Vue.axios.get(`${config.apiUrl}/cities`).then(response => {
                context.commit('SET_LOADING_STATE', false);
                context.commit('SET_CITIES', response.data);
            }).catch(e => {
                console.log(e);
            })
        },
        fetchUser ({ commit, state }) {
            if (state.token) {
                commit('SET_LOADING_STATE', true);

                Vue.axios.get(`${config.apiUrl}/account/user`).then(function (response) {
                    if (response.status === 200 && response.data) {
                        commit('SET_USER', response.data);
                    }
                    commit('SET_LOADING_STATE', false);
                }).catch(e => {
                    commit('SET_LOADING_STATE', false);
                    console.log(e);
                });
            }
        },
        startLoading (context) {
            context.commit('SET_LOADING_STATE', true);
        },
        stopLoading (context) {
            context.commit('SET_LOADING_STATE', false);
        },
        setLocale (context, locale) {
            context.commit('SET_LOCALE', locale);
        },
        setToken (context, token) {
            context.commit('SET_TOKEN', token);
        },
        clearToken (context) {
            context.commit('CLEAR_TOKEN');
        }
    },
    plugins: [vuexPersist.plugin]
});
