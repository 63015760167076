const PageIndex = () => import('./views/index.vue');
const PageResults = () => import('./views/results.vue');
const PageToS = () => import('./views/terms/tos.vue');
const PagePrivacy = () => import('./views/terms/privacy.vue');
const PageNotFound = () => import('./views/not-found.vue');
const Login = () => import('./views/account/login');
const Register = () => import('./views/account/register');
const Confirmation = () => import('./views/account/verification');
const Account = () => import('./views/account/account');
const Listing = () => import('./views/listing');

const routers = [
    {
        name: 'home',
        path: '/',
        component: PageIndex,
    },
    {
        name: 'city',
        path: '/city/:permalink',
        component: PageResults
    },
    {
        name: 'listing',
        path: '/listing/:listingId',
        component: Listing
    },    
    {
        name: 'account',
        path: '/account',
        component: Account
    },    
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'verification',
        path: '/verification/:token',
        component: Confirmation
    },
    {
        name: 'terms',
        path: '/terms-of-use',
        component: PageToS
    },
    {
        name: 'privacy',
        path: '/privacy-policy',
        component: PagePrivacy
    },
    {
        name: '404',
        path: '/not-found',
        component: PageNotFound
    },
    { 
        path: '*', 
        redirect: '/not-found'
    }
];
export default routers;
