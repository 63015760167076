<style scoped lang="less">
    @import './../styles/variables';
    
    .footer-toggle {
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: @shadow-3dp;
        z-index: 901;
        border: 0;
    }

    footer {
        z-index: 900;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-top: 1px solid @border-color-light;
        display: none;
        box-shadow: @shadow-3dp;
        
        &.fixed {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            box-shadow: none;
            display: block;
        }
        
        a {
            color: @text-color;
        }
        
        &.is-visible {
            display: block;
        }
        
        .layout {
            max-width: 1180px;
            width: 100%;
            margin: 0 auto;
            
            h3 {
                margin-bottom: 10px;
                font-size: @font-size-large;
            }
            
            ul {
                li {
                    list-style-position: inside;
                    list-style-type: none;
                }
            }
            
            .top {
                padding: 50px 10px;
            }
            
            .bottom {
                padding: 20px 10px;
                position: relative;
                
                &:before {
                    content: '';
                    height: 1px;
                    background: @border-color-light;
                    top: -20px;
                    position: relative;
                    width: 100%;
                }
                
                .copyright {
                    display: flex;
                    align-items: center;
                    
                    .logo {
                        margin-right: 20px;
                    }
                }
                
                .language-select {
                    float: right;
                }
            }
        }
    }

    @media @mobile-alt {
        .footer-toggle {
            display: none;
        }

        footer {
            position: relative;
            left: auto;
            right: auto;
            bottom: auto;
            box-shadow: none;
            display: block;

            .layout h3 {
                margin-top: 20px;
            }
        }
    }
</style>

<template>
    <div>

        <Button v-if="!fixed" @click="isVisible = !isVisible" :icon="buttonIcon" class="footer-toggle" size="large">{{ buttonText }}</Button>
    
        <footer :class="footerClass + (fixed ? 'fixed' : '')">
    
            <Content class="layout">

                <Row class="top" type="flex" justify="space-between">

                    <Col :xs="24" :sm="24" :md="6" :lg="6">
                        <div v-if="cities">
                            <h3>{{ $t('footer.cities') }}</h3>
                            <ul>
                                <li v-for="city in cities" v-if="city.listingCount">

                                    <router-link tag="a" :to="{ 
                                        name: 'city', 
                                        params: { 
                                            permalink: city._id 
                                        }
                                    }">{{ city.name }}
                                    </router-link>

                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col :xs="24" :sm="24" :md="6" :lg="6">
                        <div v-if="cities">
                            <h3>{{ $t('footer.useful') }}</h3>
                            <ul>
                                <li>
                                    <router-link tag="a" :to="{ name: 'login'}">{{ this.$t('main.login') }}</router-link>
                                </li>
                                <li>
                                    <router-link tag="a" :to="{ name: 'register'}">{{ this.$t('main.register') }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col :xs="24" :sm="24" :md="6" :lg="6">
                    </Col>

                    <Col :xs="24" :sm="24" :md="6" :lg="6">
                        <div v-if="cities">
                            <h3>{{ $t('footer.legal') }}</h3>
                            <ul>
                                <li>
                                    <router-link tag="a" :to="{ name: 'privacy'}">{{ this.$t('main.privacy_policy') }}</router-link>
                                </li>
                                <li>
                                    <router-link tag="a" :to="{ name: 'terms'}">{{ this.$t('main.tos') }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </Col>

                </Row>
                
                <Row class="bottom">

                    <Col span="16" class="copyright">
                        <img width="40" height="40" class="logo" v-lazy="'/images/logo.svg'" alt="Logo"> <span>© {{ new Date().getFullYear() }} {{ projectName }}. {{ $t('footer.copyright') }}</span>
                    </Col>
                    
                    <Col span="8">

                        <LanguageSelect />
                        
                    </Col>

                </Row>
    
            </Content>
            
        </footer>
        
    </div>
</template>

<script>

    import Vue from 'vue';
    import config from './../config';
    import LanguageSelect from './languageSelect';
    import { mapGetters } from 'vuex';
    import Trackers from './../components/trackers';

    Vue.component('LanguageSelect', LanguageSelect);

    export default {
        data() {
            return {
                projectName: config.projectName,
                isVisible: false
            }
        },
        props: {
            fixed: {
                type: Boolean,
                default: false
            }
        },
        computed: Object.assign({
            locale() {
                return this.$store.state.locale;
            },
            buttonIcon() {
                return this.isVisible ? 'md-close' : 'ios-pizza';
            },
            buttonText() {
                return this.isVisible ? this.$t('footer.button_close') : this.$t('footer.button_open');
            },
            footerClass() {
                return this.isVisible ? 'is-visible ' : '';
            }
        },
        mapGetters([
            'cities'
        ]))
    };
</script>
