<style scoped lang="less">
    .curtain {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        z-index: 10000;
        opacity: 0.7;
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        img {
            width: 40px;
            height: 40px;
            margin: 0 auto;
        }
    }
</style>

<template>
    <div class="curtain" v-if="isLoading">
        <img v-lazy="'/images/loader.svg'" alt="Loading Content">
    </div>
</template>

<script>
    export default {
        computed: {
            isLoading() {
                return this.$store.state.isLoading;
            }
        }
    }
</script>
