<style lang="less">
    @import './../styles/variables';
    
    .hooper-pagination {
        bottom: 20px;
        max-width: 100vw;
        width: 100%;

        .hooper-indicators {
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            
            .hooper-indicator {
                background-color: rgba(0, 0, 0, 0.4);
                
                &:hover, 
                &.is-active {
                    background-color: @primary-color !important;
                }
            }
        }
    }
    
    .hooper-navigation svg {
        fill: white;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 100%; 
        
        @media @mobile {
            display: none;
        }
    }
    
</style>

<style scoped lang="less">
    @import './../styles/variables';
    @import './../styles/animations';
    
    .gallery {
        position: fixed;
        z-index: 10000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh;
        height: 100%;
        padding: 0;
        background: rgba(@white, 0.8);
        overflow: auto;
        animation-duration: @default-animation-duration;
        animation-fill-mode: both;
        display: flex;
        align-items: center;

        &.create {
            animation-name: fadeIn;

            section {
                animation-name: slideInUp;
            }
        }

        &.destroy {
            animation-name: fadeOut;

            section {
                animation-name: slideOutDown;
            }
        }
        
        section {
            animation-duration: @default-animation-duration;
            animation-fill-mode: both;
        }

        .close {
            position: fixed;
            right: 30px;
            top: 30px;
            z-index: 1003;

            @media @mobile {
                right: 10px;
                top: 10px;
            }
        }

        .hooper {
            height: 100%;
            
            .hooper-list {
                height: 80vh;
            }
            
            .hooper-slide {
                display: flex;
                padding: 0 20px;

                @media @mobile-portrait {
                    padding: 0 5px;
                }

                .img {
                    border-radius: @border-radius-base;
                    background-position: 50%;
                    background-size: cover;
                    display: block;
                    width: 80vw;
                    max-width: 900px;
                    height: 60vh;
                    margin: auto auto;
                    background-repeat: no-repeat;
                    background-color: @border-color-base;
                    box-shadow: @shadow-3dp;

                    &[lazy=loading] {
                        background-size: 40px !important;
                    }

                    @media @mobile {
                        height: 40vh;
                    }

                    @media @mobile-landscape {
                        height: 90vh;
                    }
                }
            }
        }
    }
</style>

<template>
    <section class="gallery create">
        <hooper :settings="settings" :initialSlide="initialSlide" @slide="lazyLoadNextImages">
            <slide v-for="(img, indx) in images" :key="indx" :index="indx">

                <div class="img" v-lazy:background-image="img.url"></div>
            </slide>

            <hooper-navigation v-if="images.length > 1" slot="hooper-addons"></hooper-navigation>
            <hooper-pagination  v-if="images.length > 1" slot="hooper-addons"></hooper-pagination>

        </hooper>

        <div class="close-wrapper" style="height: 40px">
            <Button @click="closeGallery" class="close" type="primary" icon="md-close" shape="circle"></Button>
        </div>
    </section>

</template>

<script>
    import Vue from 'vue';
    import { 
        Hooper, 
        Slide, 
        Navigation as HooperNavigation,
        Pagination as HooperPagination} from 'hooper';
    import 'hooper/dist/hooper.css';

    Vue.component('hooper', Hooper);
    Vue.component('slide', Slide);
    Vue.component('hooper-navigation', HooperNavigation);
    Vue.component('hooper-pagination', HooperPagination);

    export default {
        props: {
            images: {
                type: Array,
                required: true
            },
            initialSlide: {
                type: Number,
                default: 0
            }
        },
        computed: {
        },
        methods: {
            closeGallery(e) {
                e.preventDefault();
                e.stopPropagation();

                this.$el.classList.add('destroy');

                setTimeout(function() {
                    this.$destroy();
                    document.body.removeChild(this.$el);
                }.bind(this), 400);
            },
            lazyLoadNextImages() {
                setTimeout(function() {
                    this.$Lazyload.lazyLoadHandler();
                }.bind(this), 300);
            }
        },
        data() {
            return {
                settings: {
                    centerMode: true,
                    itemsToShow: 1.6,
                    breakpoints: {
                        1200: {
                            itemsToShow: 1.6
                        },
                        800: {
                            itemsToShow: 1.4
                        },
                        0: {
                            itemsToShow: 1.2
                        }
                    }
                }
            }
        },
        mounted() {
            const body = document.body;
            const details = document.querySelector('#details');
            const scrollY = `-${window.scrollY}px`;

            body.style.position = 'fixed';
            body.style.top = scrollY;
            
            if (details) {
                details.style.position = 'fixed';
            }
        },
        beforeDestroy () {
            const body = document.body;
            const details = document.querySelector('#details');
            const scrollY = body.style.top;
            
            body.style.position = '';
            body.style.top = '';

            if (details) {
                details.style.position = '';
            }
            
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        },
        created () {
            setTimeout(function() {
                this.$el.classList.remove('create');
            }.bind(this), 400);
        }
    };
</script>
