<template>
    <time>{{ timeAgo }}</time>
</template>

<script>
    import TimeAgo from 'javascript-time-ago';
    import en from 'javascript-time-ago/locale/en';
    import de from 'javascript-time-ago/locale/de';
    import vi from 'javascript-time-ago/locale/vi';

    // TODO: add on locale change
    TimeAgo.addLocale(en);
    TimeAgo.addLocale(de);
    TimeAgo.addLocale(vi);
    
    export default {
        props: {
            createdAt: {
                type: String,
                required: true
            },
        },
        computed: {
            timeAgo() {
                return (new TimeAgo(this.$store.state.locale)).format(new Date(this.createdAt));
            }
        }
    };
</script>
