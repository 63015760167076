<style scoped lang="less">
    .city {
        padding-right: 20px;
    }

</style>

<template>
    
    <Dropdown v-if="cities" class="city-select">

        <Button size="large">
            <Icon type="ios-pin"></Icon>
            
            <span v-if="isValidCity">{{ city.name }}</span>
            <span v-else>{{ $t('index.hero.button') }}</span>
            
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        
        <DropdownMenu slot="list">
            <DropdownItem v-for="city in cities" v-if="city.listingCount" :key="'userbar-city-' + city._id" :name="city._id">

                <router-link tag="span" :to="{ 
                                        name: 'city', 
                                        params: { 
                                            permalink: city._id 
                                        }
                                    }">
                    <span class="city">{{ city.name }}</span> <Badge style="float: right" :count="city.listingCount" type="primary" overflow-count="10000"></Badge>
                </router-link>
                
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
    
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: Object.assign(mapGetters(['cities']), {
            city() {
                return this.$store.getters.city(this.$route.params.permalink);
            },
            isValidCity() {
                return !!this.$store.getters.city(this.$route.params.permalink);
            }
        })
    };
</script>
