import config from '../config';

let util = {};

util.title = function (title) {
    title = title ? title : config.projectName;
    
    window.document.title = title;
};

export default util;
