module.exports = {
    "de-DE": {
        main: {
            login: 'Login',
            register: 'Registrieren',
            tos: 'Nutzungsbedingungen',
            privacy_policy: 'Datenschutzregelung'
        },
        index: {
            city_card: '{listings} Angebote in <b class="city-name">{city}</b> von {provider} Anbietern',
            hero: {
                heading: 'Eine neue Wohnung finden sollte',
                phrases: {
                    easy: 'einfach sein.',
                    fun: 'Spaß machen.',
                    stress_free: 'streßfrei sein.',
                    fast: 'schnell gehen.'
                },
                button: 'Wähle deine Stadt'
            },
            explain: {
                main_heading: 'Was genau ist {projectName}?',
                general_problem_heading: 'Die generelle Herausforderung',
                general_problem_text: 'Eine neue Wohnung zu finden kann nervenaufreibend und zeitintensiv sein. Menschen, die aktiv nach einer neuen Wohnung suchen, müssen viel Zeit in Anspruch nehmen und diverse Seiten der einzelnen Immobilienanbieter durchstöbern, um Anfragen bei den Vermietern stellen zu können.<br><br> Das kann nerven!',
                time_heading: 'Wer hat dafür schon Zeit?',
                time_text: 'Mit {projectName} bekommst du eine Übersicht aller Wohnungsportale deiner Stadt an einem Ort und siehst in einem Livestream, welche Wohnung zuletzt zum Vermieten freigegeben und inseriert wurde.<br><br> Du sparst dir so nicht nur jede Menge Zeit, sondern überlässt unserer Suchmaschine die Suche nach deiner neuen Wohnung.',
                easy_search_heading: 'Suche leicht gemacht',
                easy_search_text: 'Die {projectName} Suchmaschine ist <i>ununterbrochen</i> auf der Suche nach deiner neuen Wohnung. Erzähl uns einfach, wie deine nächste Wohnung auszusehen hat, welche Eigenschaften sie haben und in welchen Gegenden sie am besten sein sollte.<br><br>Wir informieren dich <i>sofort</i> darüber, wenn wir eine neue Wohnung nach deinem Geschmack finden.',
                instant_info_heading: 'Werde direkt informiert',
                instant_info_text: 'Wenn die {projectName} Suchmaschine eine Wohnung findet, die deinen Kriterien entspricht, dann schicken wir dir <i>sofort</i> eine Nachricht.<br><br>Da du einer der ersten sein wirst, der von der neuen Wohnung erfährt, ist es sehr wahrscheinlich, dass du einen Besichtigungstermin bekommst.',
                steps: {
                    heading: 'Alles klar, los geht\'s!',
                    step_1_heading: 'Schritt 1',
                    step_1_text: 'Erstelle ein kostenloses Profil und erzähle uns in welcher Stadt du deine neue Wohnung suchst. Grenze deinen Suche ein, indem du Kriterien definierst, welche deine neue Wohnung beschreiben.<br><br> Du brauchst eine Klimaanlage? Finden wir für dich, kein Problem!',
                    step_2_heading: 'Schritt 2',
                    step_2_text: 'Wir schicken für dich die {projectName} Suchmaschine auf die Jagd.<br>Auch, wenn du schläfst, ist die Suchmaschine ununterbrochen auf der Suche nach deiner neuen Wohnung und gibt nicht auf, bis sie etwas gefunden hat.',
                    step_3_heading: 'Schritt 3',
                    step_3_text: 'Die {projectName} Suchmaschine hat etwas gefunden, was deinen Kriterien entspricht!<br>Damit du zu denn ersten gehörst, die sich auf die neue Wohnung bewerben können, schicken wir dir <i>sofort</i> eine Nachricht mit den Informationen zu der neuen Wohnungn und du kannst dich umgehend bei dem Vermieter melden.',
                },
                next_heading: 'Weitere Städte',
                next_text: 'Wir sind konstant dabei unseren Service zu erweitern und fügen neue Städte zur Suchmaschine hinzu. Folgende Städte stehen bald zur Verfügung.'
            },
            cta_block: {
                heading: 'Jeden Tag frische Wohnungen im Postfach',
                button: 'Jetzt anmelden'
            }
        },
        newsletter_ad: {
            heading: 'Jeden Tag frische Wohnungen im Postfach',
            text: 'Möchtest du über neue Wohnungen informiert werden?<br><br>Erstelle ein kostenloses Benutzerkonto, sag uns wo genau du deine neue Wohnung suchst und welche Kriterien sie erfüllen muss und wir schreiben dir sobald wir neue Treffer haben, die genau deiner Suche entsprechen.',
            button: 'Jetzt anmelden'
        },
        results: {
            city_preposition: 'in',
            provider: 'Anbieter | Anbietern',
            subheading: 'von {x} verschiedenen {p}'
        },
        pagination: {
            pagination_preposition: 'von',
            listings: 'Angebotes'
        },
        userbar: {
            languages: 'Sprachen',
            chose_city: 'Wähle eine Stadt',
            city: 'Stadt'
        },
        footer: {
            copyright: 'All rights reserved.',
            cities: 'Städte',
            useful: 'Nützliche Links',
            legal: 'Rechtliches',
            button_open: 'Sprache, Bedingungen und Datenschutz',
            button_close: 'Schließen'
        },
        filter: {
            missingProvider: 'Anbieter nicht dabei? Sag uns Bescheid!',
            toggleMap: 'Karte anzeigen',
            listing_age_heading: 'Alter der Angebote',
            listing_age_label: 'Es werden alle {x} angezeigt.',
            lookback_1: 'Angebote der letzten 24 Stunden',
            lookback_2: 'Angebote der letzten 2 Tage',
            lookback_3: 'Angebote der letzten 3 Tage',
            lookback_4: 'Angebote der letzten 4 Tage',
            lookback_5: 'Angebote der letzten 5 Tage',
            lookback_6: 'Angebote der letzten 6 Tage',
            lookback_7: 'Angebote der letzten Woche',
            lookback_14: 'Angebote der letzten 2 Wochen',
            lookback_31: 'Angebote des letzten Monats',
            lookback_heading: 'Ende der Liste',
            lookback_info: 'Das waren alle {lookback} in {city}.',
            lookback_continue: 'Hier muss deine Suche aber nicht aufhören. Klicke hier um dir alle {x} anzusehen.',
            room: 'Zimmer | Zimmer',
            squaremeter: 'Quadratmeter | Quadratmeter',
            in_between_label: 'Zwischen {x} und {y} {type}',
            room_heading: 'Anzahl Zimmer',
            room_default_label: 'Zeige alle Angebote jeglicher Zimmeranzahl',
            size_heading: 'Größe des Angebots',
            size_default_label: 'Zeige alle Angebote jeglicher Größe',
            price_heading: 'Preisspanne',
            price_default_label: 'Zeige alle Angebote jeglichen Preises',
            equipment_heading: 'Ausstattung',
            reset: 'Filter zurücksetzen',
            features_label: 'Klimaanlage, WLAN und {x} weitere',
            feature: {
                air_conditioning: 'Klimaanlage',
                fan: 'Deckenventilator',
                internet: 'Internet',
                wifi: 'WLAN',
                tv: 'Fernseher',
                pool: 'Swimmingpool',
                playground: 'Spielplatz',
                fitness: 'Fitnessraum',
                gym: 'Fitnessstudio',
                balcony: 'Balkon',
                terrace: 'Terrasse',
                garden: 'Garten',
                view: 'Guter Ausblick',
                washing: 'Wäsche',
                kitchen: 'Küche',
                security: 'Sicherheit',
                service: 'Service',
                parking: 'Parkplatz',
                car_access: 'PKW Zugänglich',
                concierge: 'Concierge service',
                tennis: 'Tennis'
            },
            providers_heading: 'Anbieter',
            providers_label: 'Angebote von {x} Anbietern',
            zero_results_heading: 'Oh nein, keine Ergebnisse',
            zero_results_text: 'Es tut uns Leid, aber für deine Suche gibt es leider keine Ergebnisse. Du kannst entweder einige Filter wieder entfernen oder die Filter komplett zurücksetzen.',            
        },
        listing: {
            image: 'Bild | Bilder',
            room: 'Zimmer | Zimmer',
            sqm: 'Quadratmeter | Quadratmeter',
            rent: 'Miete/Monat',
            cta: 'Ansehen',
            more: '+{x} weitere'
        },
        details: {
            heading_features: 'Ausstattungsmerkmale',
            heading_description: 'Beschreibung',
            heading_location: 'Lage der Unterkunft',
            website_button: 'Zur Website des Anbieters',
            all_pictures: 'Alle {x} Bilder anschauen'
        },
        404: {
            heading: 'Seite nicht gefunden',
            text: 'Oha, diese Seite scheint nicht zu existieren :('
        },
        account: {
            login: {
                heading: 'Login',
                email: 'E-mail',
                password: 'Passwort',
                signin: 'Einloggen',
                signup: 'Jetzt registrieren!',
                remember_me: 'Eingeloggt bleiben',
                no_account: 'Du hast noch keinen Account?',
                validation: {
                    no_mail: 'Bitte gib deine E-mail ein',
                    invalid_mail: 'Bitte gib eine valide E-mail ein',
                    no_password: 'Bitte gib dein Passwort ein',
                    invalid_user: 'Ein Benutzerkonto mit dieser E-mail-Adresse existiert nicht',
                    user_not_verified: 'Dieses Benutzerkonto wurde noch nicht verifiziert. Bitte überprüfe deine E-mails und folge dem angegebenen Link, um dein Konto zu bestätigen.',
                    wrong_password: 'Dein Passwort scheint falsch zu sein',
                    random_error: 'Oh nein, auf unserer Seite ist etwas schief gelaufen. Bitte versuche es erneut.',
                }
            },
            register: {
                heading: 'Registrierung',
                firstname: 'Vorname',
                lastname: 'Nachname',
                email: 'E-mail',
                password: 'Passwort',
                signup: 'Anmeldung abschicken',
                signin: 'Jetzt einloggen!',
                legal_text: 'Mit dem Klick auf "Anmeldung abschicken" erklärst du dich mit unseren <a target="_blank" href="/terms-of-use">{terms}</a> einverstanden und hast unsere <a target="_blank" href="/privacy-policy">{policy}</a> gelesen.',
                have_account: 'Du hast bereits ein Benutzerkonto?',
                success_heading: 'Registrierung erfolgreich!',
                success_text: 'Deine Registrierung war erfolgreich. Bitte überprüfen deine E-mails, um dein Benutzerkonto zu bestätigen.',
                back: 'Zurück zur Startseite',
                validation: {
                    no_firstname: 'Bitte gib deinen Vornamen an',
                    no_lastname: 'Bitte gib deinen Nachnamen an',
                    no_email: 'Bitte gib deine E-mail Adresse an',
                    invalid_email: 'Es scheint als wäre deine E-mail Adresse falsch geschrieben',
                    no_password: 'Bitte gib dein Passwort an',
                    short_password: 'Dein Passwort benötigt mindestens 8 Zeichen',
                    user_exists: 'Ein Konto mit dieser E-mail existiert bereits. Bist du sicher, dass du dich nicht anmelden möchtest?',
                    random_error: 'Oh nein, auf unserer Seite ist etwas schief gelaufen. Bitte versuche es erneut.',
                }
            },
            verify: {
                loading: 'E-mail wird überprüft..',
                success_heading: 'E-mail-Bestätigung war erfolgreich!',
                success_text: 'Hey { name },<br> du hast erfolgreich dein Benutzerkonto mit der E-mail Adresse <b>{ email }</b> verifiziert und bist nun startklar.<br><br> Jetzt wird es Zeit dein neues Zuhause zu finden!',
                success_button: 'Jetzt einloggen!',
                fail_heading: 'E-mail-Bestätigung nicht erfolgreich!',
                fail_text: 'Es tut uns leid, aber es scheint, dass das mitgelieferte Verifizierungstoken abgelaufen ist oder bereits verwendet wurde.',
                validation: {
                    token_invalid: 'Das Verifikationstoken ist ungültig',
                    token_used: 'Token wurde bereits verwendet. Sicher, dass du dich nicht nur einloggen möchten?',
                    random_error: 'Oh nein, auf unserer Seite ist etwas schief gelaufen. Bitte versuche es erneut.',
                }
            }
        }
    },
    "en-GB": {
        main: {
            login: 'Login',
            register: 'Register',
            tos: 'Terms of use',
            privacy_policy: 'Privacy policy'
        },
        index: {
            city_card: '{listings} listings in <b class="city-name">{city}</b> from {provider} providers',
            hero: {
                heading: 'Finding a new home should',
                phrases: {
                    easy: 'be easy.',
                    fun: 'be fun.',
                    stress_free: 'be stress free.',
                    fast: 'be fast.'
                },
                button: 'Choose your city'
            },
            explain: {
                main_heading: 'What is {projectName}?',
                general_problem_heading: 'The challenge',
                general_problem_text: 'Finding a new apartment can be very time-consuming and nerve-wracking. People who are actively looking for a new place to live, need to spend a lot of time and often compete for the best listings. <br> <br> This can be very annoying!',
                time_heading: 'Who has time for that?',
                time_text: 'With {projectName} you get an overview of the latest advertised listings of all trusted real estate portals of your city in one place in a livestream. <br> <br>Leave the the apartment hunt to our search engine and save some precious time.',
                easy_search_heading: 'Search made easy',
                easy_search_text: 'The {projectName} search engine is <i>constantly</i> looking for your new home. Just tell us what your next apartment should look like, which features it needs and in which city you want it to be. <br> <br> We\'ll let you know <i>immediately</i> in case we find a newly advertised apartment that suits your needs.',
                instant_info_heading: 'Be informed instantly',
                instant_info_text: 'We will send you a message immediately in case the {projectName} search engine finds apartments that meet your criteria.<br><br> Since you will be one of the firsts to get to know about the new listing, it is very likely you will get an appointment with the landlord.',
                steps: {
                    heading: 'Let\'s get started!',
                    step_1_heading: 'Step 1',
                    step_1_text: 'Create a free account and tell us in which city you are looking for your new apartment. Narrow down your search by defining criteria that describe your new home. <br> <br> Need Air Conditioning? We\'ve got you covered, no problem!',
                    step_2_heading: 'Step 2',
                    step_2_text: 'The {projectName} search engine will do the heavy lifting for you! <br><br> Even when you\'re sleeping, the search engine is constantly on the lookout for your new home and will not give up until it finds it.',
                    step_3_heading: 'Step 3',
                    step_3_text: 'The {projectName} search engine has found something that meets your criteria, yeah! <br>We will send you an <i>instant</i> message with the information about the new apartment so you can contact the owner immediately and be among the first to apply for your new home.',
                },
                next_heading: 'More cities to come',
                next_text: 'We are constantly expanding our service and adding new cities to the search engine. The following cities will be available soon.'
            },
            cta_block: {
                heading: 'Receive in real-time listings in your mailbox',
                button: 'Get Started'
            }
        },
        newsletter_ad: {
            heading: 'Fresh listings every day in your inbox',
            text: 'Receive newly published listings fitting your criteria directly into your mailbox.',
            button: 'Get Started'
        },
        results: {
            city_preposition: 'in',
            provider: 'provider | providers',
            subheading: 'from {x} different {p}'
        },
        pagination: {
            pagination_preposition: 'of',
            listings: 'listings'
        },
        userbar: {
            languages: 'Languages',
            chose_city: 'Chose your city',
            city: 'City'
        },
        footer: {
            copyright: 'All rights reserved.',
            cities: 'Cities',
            useful: 'Useful',
            legal: 'Legal',
            button_open: 'Language, Terms & Privacy',
            button_close: 'Close'
        },
        filter: {
            missingProvider: 'Provider missing? Let us know!',
            toggleMap: 'Show map',
            listing_age_heading: 'Age of listings',
            listing_age_label: 'You are currently seeing all {x}.',
            lookback_1: 'Listings from the last 24 hours',
            lookback_2: 'Listings from the last 2 days',
            lookback_3: 'Listings from the last 3 days',
            lookback_4: 'Listings from the last 4 days',
            lookback_5: 'Listings from the last 5 days',
            lookback_6: 'Listings from the last 6 days',
            lookback_7: 'Listings from the past week',
            lookback_14: 'Listings from the last 2 weeks',
            lookback_31: 'Listings from the last month',
            lookback_heading: 'End of the list',
            lookback_info: 'These were all {lookback} in {city}.',
            lookback_continue: 'Your search does not need to be over here. Click here to see all {x}.',
            room: 'room | rooms',
            squaremeter: 'square meter | square meters',
            in_between_label: 'Between {x} and {y} {type}',
            room_heading: 'Number of rooms',
            room_default_label: 'Show listings of any room count',
            size_heading: 'Square meters',
            size_default_label: 'Show listings of any size',
            price_heading: 'Price range',
            price_default_label: 'Show listings of any price',
            equipment_heading: 'Features',
            reset: 'Reset filter',
            features_label: 'Air Conditioning, Wi-Fi and {x} more',
            feature: {
                air_conditioning: 'Air Conditioning',
                fan: 'Ceiling Fan',
                internet: 'Internet',
                wifi: 'Wi-Fi',
                tv: 'Television',
                pool: 'Swimming Pool',
                playground: 'Playground',
                fitness: 'Fitness room',
                gym: 'Gym',
                balcony: 'Balcony',
                terrace: 'Terrace',
                garden: 'Garden',
                view: 'Nice view',
                washing: 'Washing',
                kitchen: 'Kitchen',
                security: 'Security',
                service: 'Service',
                parking: 'Parking',
                car_access: 'Car access',
                concierge: 'Concierge service',
                tennis: 'Tennis'
            },
            providers_heading: 'Providers',
            providers_label: 'Listings from {x} providers',
            zero_results_heading: 'Oh no, zero results',
            zero_results_text: 'Sorry, there seem to be no matches for your search. You can either remove some of your filters or reset them all.',
        },
        listing: {
            image: 'image | images',
            room: 'Room | Rooms',
            sqm: 'Square meter | Square meters',
            rent: 'Rent/month',
            cta: 'See details',
            more: '+{x} more'
        },
        details: {
            heading_features: 'Features',
            heading_description: 'Description',
            heading_location: 'Location',
            website_button: 'Go to provider',
            all_pictures: 'See all {x} pictures'
        },
        404: {
            heading: 'Page not found',
            text: 'Sorry, the page you\'re looking for does not exist :('
        },
        account: {
            login: {
                heading: 'Login',
                email: 'E-mail',
                password: 'Password',
                signin: 'Sign in',
                signup: 'Sign up!',
                remember_me: 'Remember me',
                no_account: 'Don\'t have an account?',
                validation: {
                    no_mail: 'Please fill in your email',
                    invalid_mail: 'Please enter a valid email',
                    no_password: 'Please enter your password',
                    invalid_user: 'A user account with this email address does not exist',
                    user_not_verified: 'This user account is not verified yet. Please check your emails and follow the provided link insided to verify your account.',
                    wrong_password: 'Your password seems to be wrong',
                    random_error: 'Oh no, something went wrong on our side. Please try again.',
                }
            },
            register: {
                heading: 'Register',
                firstname: 'First name',
                lastname: 'Last name',
                email: 'E-mail',
                password: 'Password',
                signup: 'Sign up',
                signin: 'Sign in!',
                legal_text: 'By clicking Sign up, you agree to our <a target="_blank" href="/terms-of-use">{terms}</a> and that you have read our <a target="_blank" href="/privacy-policy">{policy}</a>.',
                have_account: 'Already have an account?',
                success_heading: 'Registration successful!',
                success_text: 'Your registration was successful. Please check your emails to verify your account.',
                back: 'Back to start',
                validation: {
                    no_firstname: 'Please fill in your first name',
                    no_lastname: 'Please fill in your last name',
                    no_email: 'Please fill in your email',
                    invalid_email: 'Seems like the entered email is invalid',
                    no_password: 'Please fill in your password',
                    short_password: 'Please enter a password with at least 8 characters',
                    user_exists: 'An account with this email already exists. Are you sure you don\'t want to log in?',
                    random_error: 'Oh no, something went wrong on our side. Please try again.',
                }
            },
            verify: {
                loading: 'Verifying email..',
                success_heading: 'E-mail verification successful!',
                success_text: 'Hey {name},<br> you have successfully verified your e-mail address <b>{email}</b> and you are ready to go now.<br><br> Time to find your new home!',
                success_button: 'Log in!',
                fail_heading: 'E-mail verification failed!',
                fail_text: 'Sorry, but it seems like the supplied verification token has either expired or has already been used.',
                validation: {
                    token_invalid: 'The verification token is invalid',
                    token_used: 'Token has already been used. Sure you don\'t just want to log in? ',
                    random_error: 'Oh no, something went wrong on our side. Please try again.',
                }
            }
        }
    },
    "fr-FR": {
        main: {
            login: 'Connexion',
            register: 'Inscription',
            tos: 'Conditions d\'utilisations',
            privacy_policy: 'Politique de confidentialité'
        },
        index: {
            city_card: '{listings} annonces à <b class="city-name">{city}</b> de {provider} sources',
            hero: {
                heading: 'Trouver un logement devrait',
                phrases: {
                    easy: 'être simple.',
                    fun: 'être amusant.',
                    stress_free: 'être sans stress.',
                    fast: 'être rapide.'
                },
                button: 'Choisir votre ville'
            },
            explain: {
                main_heading: 'Qu\'est-ce que {projectName} ?',
                general_problem_heading: 'La problématique',
                general_problem_text: 'Trouver un logement peut-être chronophage et stressant. Les personnes à la recherche active d\'un nouveau domicile ont besoin de passer beaucoup de temps à parcourir de nombreuses sources d\'annonces et derrière faire la queue pour déposer un dossier. <br> <br> Cela peut rapidement devenir très frustrant !',
                time_heading: 'Qui a le temps pour ça ?',
                time_text: 'Avec {projectName} vous obtenez à un seul endroit et en temps réel, une vue d\'ensemble sur les dernières publications de toutes les sources listant des annonces de locations immobilières dans votre ville. <br> <br> Confiez-nous votre recherche et gagner du temps.',
                easy_search_heading: 'Une recherche simplifiée',
                easy_search_text: '{projectName} recherche <i>constamment</i> de nouvelles annonces. Dîtes-nous simplement à quoi votre prochain logement doit ressembler, quels critères il doit respecter et dans quelle ville vous souhaitez que votre logement soit situé. <br> <br> Nous vous informerons <i>immédiatement</i> si une annonce correspond à l\'ensemble de vos critères.',
                instant_info_heading: 'Notification instantanée',
                instant_info_text: 'Nous vous envoyons instantanément un message lorsque le moteur de recherche {projectName} trouve un logement qui correspond à vos critères.<br><br> Comme vous serez l\'un des premiers à voir l\'annonce, vous aurez ainsi de grandes chances d\'obtenir un entretien avec le propriétaire.',
                steps: {
                    heading: 'Comment ça marche ?',
                    step_1_heading: 'Étape 1',
                    step_1_text: 'Créez un compte gratuitement et dites-nous dans quelle ville vous recherchez un logement. Affinez vos critères en sélectionnant les options que vous souhaitées. <br> Besoin d\'un Ascenseur ? Pas de problème, nous allons trouver!',
                    step_2_heading: 'Étape 2',
                    step_2_text: 'Le moteur de recherche {projectName} se charge de la plus grande partie du boulot ! <br><br> Même lorsque vous dormez, notre outil continue à rechercher de nouvelles annonces et n\'abandonnera pas avant d\'avoir trouvé.',
                    step_3_heading: 'Étape 3',
                    step_3_text: 'Bravo ! {projectName} a trouvé une annonce qui correspond à vos critères ! <br>Recevez <i>directement</i> un message avec les informations de l\'annonce pour que vous puissiez contacter rapidement le propriétaire et que vous soyez parmi les premiers à postuler.',
                },
                next_heading: 'Bientôt présent dans de nouvelles villes',
                next_text: 'Nous sommes en constantes expansions avec de nouvelles villes ajoutées à notre plateforme. Découvrez les villes prochainement disponibles.'
            },
            cta_block: {
                heading: 'Recevez directement des annonces dans votre boîte mail.',
                button: 'Recevoir Gratuitement'
            }
        },
        newsletter_ad: {
            heading: 'Annonces directement dans votre boîte mail',
            text: 'Ouvrez votre compte, donnez-nous vos critères et nous vous enverrons les nouvelles annonces correspondantes chaque jour.',
            button: 'Recevoir Gratuitement'
        },
        results: {
            city_preposition: 'à',
            provider: 'source | sources',
            subheading: 'provenant de {x} {p} differentes'
        },
        pagination: {
            pagination_preposition: 'sur',
            listings: 'annonces'
        },
        userbar: {
            languages: 'Langages',
            chose_city: 'Choisir votre ville',
            city: 'Ville'
        },
        footer: {
            copyright: 'Tout droit réservé.',
            cities: 'Villes',
            useful: 'Infos pratiques',
            legal: 'Légal',
            button_open: 'Langage, Termes & Confidentialité',
            button_close: 'Fermer'
        },
        filter: {
            missingProvider: 'Source manquante ? Faites-le-nous savoir !',
            toggleMap: 'Afficher la carte',
            listing_age_heading: 'Date des annonces',
            listing_age_label: 'Vous êtes en train de visualiser toutes les {x}.',
            lookback_1: 'Annonces des dernières 24 heures',
            lookback_2: 'Annonces 2 derniers jours',
            lookback_3: 'Annonces 3 derniers jours',
            lookback_4: 'Annonces 4 derniers jours',
            lookback_5: 'Annonces 5 derniers jours',
            lookback_6: 'Annonces 6 derniers jours',
            lookback_7: 'Annonces de la semaine dernière',
            lookback_14: 'Annonces de 2 semaines',
            lookback_31: 'Annonces du mois dernier',
            lookback_heading: 'Fin de la liste',
            lookback_info: 'Ceci étaient toutes les {lookback} à {city}.',
            lookback_continue: 'Votre recherche n\'a pas à se trouver ici. Click here to see all {x}.',
            room: 'pièce | pièces',
            squaremeter: 'mètre carré | mètres carrés',
            in_between_label: 'Entre {x} et {y} {type}',
            room_heading: 'Nombre de pièces',
            room_default_label: 'Afficher les annonces de tout nombre de pièces',
            size_heading: 'Surface en m2',
            size_default_label: 'Afficher les annonces de toute surface',
            price_heading: 'Fourchette de prix',
            price_default_label: 'Afficher les annonces de tout prix',
            equipment_heading: 'Options',
            reset: 'Réinitialiser les filtres',
            features_label: 'Climatisation, Wi-Fi et {x} de plus',
            feature: {
                air_conditioning: 'Climatisation',
                fan: 'Ventilateur',
                internet: 'Internet',
                wifi: 'Wi-Fi',
                tv: 'Télévision',
                pool: 'Piscine',
                playground: 'Aire de jeux',
                fitness: 'Salle de fitness',
                gym: 'Gym',
                balcony: 'Balcon',
                terrace: 'Terrasse',
                garden: 'Jardin',
                view: 'Vue',
                washing: 'Machine à laver',
                kitchen: 'Cuisine',
                security: 'Securité',
                service: 'Service',
                parking: 'Parking',
                car_access: 'Accès véhicule',
                concierge: 'Conciergerie',
                tennis: 'Tennis'
            },
            providers_heading: 'Annonces',
            providers_label: 'Annonces de {x} sources',
            zero_results_heading: 'Oh non, aucun resultat',
            zero_results_text: 'Désolé, il semblerait qu\'il n\'y ai aucune annonce correspondant à vos critères. Vous pouvez soit retirer quelques filtres ou tout réinitialiser.',
        },
        listing: {
            image: 'image | images',
            room: 'Pièce | Pièces',
            sqm: 'Mètre carré | Mètres carrés',
            rent: 'Loyer mensuel',
            cta: 'Détails',
            more: '+{x} plus'
        },
        details: {
            heading_features: 'Options',
            heading_description: 'Description',
            heading_location: 'Localisation',
            website_button: 'Aller sur le site de l\'annonceur',
            all_pictures: 'Voir les {x} images'
        },
        404: {
            heading: 'Page introuvable',
            text: 'Désolé, la page que vous recherchez n\'existe pas :('
        },
        account: {
            login: {
                heading: 'Connexion',
                email: 'E-mail',
                password: 'Mot de passe',
                signin: 'Se connecter',
                signup: 'S\'inscrire!',
                remember_me: 'Se souvenir de moi',
                no_account: 'Pas de compte ?',
                validation: {
                    no_mail: 'Merci de remplir votre email',
                    invalid_mail: 'Merci d\'entrer une adresse email valide',
                    no_password: 'Merci d\'entrer votre mot de passe',
                    invalid_user: 'Aucun compte utilisant cette adresse n\'existe',
                    user_not_verified: 'Ce compte n\'est pas encore vérifié. Merci de vérifier vos emails et de cliquer sur le lien pour vérifier votre compte.',
                    wrong_password: 'Votre mot de passe semble être incorrect',
                    random_error: 'Oh non, quelquechose d\'imprévu s\'est passé. Merci de réessayer.',
                }
            },
            register: {
                heading: 'Inscription',
                firstname: 'Prénom',
                lastname: 'Nom',
                email: 'E-mail',
                password: 'Mot de passe',
                signup: 'S\'inscrire',
                signin: 'Se connecter!',
                legal_text: 'En cliquant sur S\'inscrire, vous acceptez nos <a target="_blank" href="/terms-of-use">{terms}</a> et vous déclaré avoir lu notre <a target="_blank" href="/privacy-policy">{policy}</a>.',
                have_account: 'Vous avez déjà un compte ?',
                success_heading: 'Inscription réussie!',
                success_text: 'Inscription réussie. Merci de contrôler vos emails pour vérifier votre compte.',
                back: 'Retour au départ',
                validation: {
                    no_firstname: 'Merci de remplir votre prénom',
                    no_lastname: 'Merci de remplir votre nom',
                    no_email: 'Merci de remplir votre email',
                    invalid_email: 'Il semblerait que l\'email saisi est invalide',
                    no_password: 'Merci de remplir votre mot de passe',
                    short_password: 'Merci de choisir un mot de passe avec au moins 8 caractères',
                    user_exists: 'Un compte avec cette adresse email existe déjà. Êtes-vous certains de ne pas vouloir vous connecter ?',
                    random_error: 'Oh non, quelquechose c\'est mal passé de votre côté. Merci de réessayer.',
                }
            },
            verify: {
                loading: 'Verification de votre adresse email..',
                success_heading: 'Vérification E-mail réussie!',
                success_text: 'Bonjour {name},<br> vous avez vérifier votre adresse email <b>{email}</b> et vous êtes prêt à commencer.<br><br> Il est temps de trouver votre logement!',
                success_button: 'Se connecter!',
                fail_heading: 'verification E-mail échouée!',
                fail_text: 'Désolé, mais il semblerait que le token de vérification a soit expiré ou a déjà été utilisé.',
                validation: {
                    token_invalid: 'Le token de vérification est invalide',
                    token_used: 'Le Token a déjà été utilisé. Vous-êtes sur de ne pas vouloir vous connecter ?',
                    random_error: 'Oh non, quelquechose c\'est mal passé de votre côté. Merci de réessayer.',
                }
            }
        }
    },
    "vi-VN": {
        main: {
            login: 'Đăng nhập',
            register: 'Đăng ký',
            tos: 'Điều khoản sử dụng',
            privacy_policy: 'Chính sách bảo mật'
        },        
        index: {
            city_card: '{listings} danh sách ở <b class="city-name"> {city} </b> từ {provider} nhà cung cấp',
            hero: {
                heading: 'Tìm một địa điểm mới thật',
                phrases: {
                    easy: 'dễ dàng.',
                    fun: 'vui vẻ.',
                    stress_free: 'thư giãn.',
                    fast: 'nhanh chóng.'
                },
                button: 'Chọn thành phố'
            },
            explain: {
                main_heading: 'Chính xác thì {projectName} là gì?',
                general_problem_heading: 'Thử thách chung',
                general_problem_text: 'Tìm một căn hộ mới có thể gây stressful và tốn thời gian. Những người đang tích cực tìm kiếm một ngôi nhà mới cần dành nhiều thời gian và duyệt qua các trang khác nhau của các nhà cung cấp bất động sản riêng lẻ để có thể yêu cầu chủ nhà. <br> <br> Điều này thật là phiền toái !',
                time_heading: 'Ai có thời gian cho việc đó ?',
                time_text: 'Với {projectName}, bạn sẽ có cái nhìn tổng quan về các danh sách được quảng cáo mới nhất từ tất cả các cổng thông tin bất động sản đáng tin cậy của thành phố bạn muốn.  <br> <br>  Hãy để việc săn tìm căn hộ cho công cụ tìm kiếm của chúng tôi và tiết kiệm rất nhiều thời gian theo cách này.',
                easy_search_heading: 'Tìm kiếm dễ dàng',
                easy_search_text: 'Công cụ {projectName} sẽ <i> luôn </i> tìm kiếm căn hộ mới cho bạn. Chỉ cần cho chúng tôi tôi biết căn hộ tiếp theo của bạn sẽ trông như thế nào, nội thất  đặc trưng mà bạn cần là gì và bạn muốn chúng ở khu vực nào. <br> <br> Chúng tôi sẽ cho bạn biết ngay lập tức trong trường hợp chúng tôi tìm thấy một căn hộ mới được quảng cáo phù hợp với các chỉ số của bạn.',
                instant_info_heading: 'Được thông báo tức thì',
                instant_info_text: 'Chúng tôi sẽ gửi tin nhắn cho bạn ngay lập tức trong trường hợp công cụ tìm kiếm {searchName} tìm được căn hộ đáp ứng tiêu chí của bạn. <br> <br> Vì bạn sẽ là một trong những người đầu tiên biết về danh sách mới, nên rất có thể bạn sẽ có một cuộc hẹn với chủ nhà.',
                steps: {
                    heading: 'Bắt đầu nào',
                    step_1_heading: 'Bước 1',
                    step_1_text: 'Tạo một hồ sơ miễn phí và cho chúng tôi biết bạn đang tìm căn hộ mới ở thành phố nào. Thu hẹp tìm kiếm của bạn bằng cách xác định các tiêu chí mô tả ngôi nhà mới của bạn. <br> <br> Cần điều hòa không khí? Chúng tôi tìm cho bạn, không có vấn đề!',
                    step_2_heading: 'Bước 2',
                    step_2_text: 'Công cụ tìm kiếm Taybalo sẽ giúp bạn ! <br><br> Ngay cả khi bạn đang ngủ, công cụ tìm kiếm vẫn liên tục tìm kiếm ngôi nhà mới cho bạn và sẽ không từ bỏ cho đến khi tìm thấy nó.',
                    step_3_heading: 'Bước 3',
                    step_3_text: 'Công cụ tìm kiếm {projectName} đã tìm thấy thứ gì đó đáp ứng tiêu chí của bạn! <br> Để trở thành một trong những người đầu tiên đăng ký căn hộ mới, chúng tôi sẽ gửi cho bạn một tin nhắn <i> tức thì </i> với thông tin căn hộ mới và bạn có thể liên hệ với chủ sở hữu ngay lập tức.',
                },
                next_heading: 'Các thành phố khác',
                next_text: 'Chúng tôi liên tục mở rộng dịch vụ của mình và thêm các thành phố mới vào công cụ tìm kiếm. Các thành phố sau đây sẽ sớm được cung cấp.'
            },
            cta_block: {
                heading: 'Danh sách mới mỗi ngày trong hộp thư đến của bạn',
                button: 'Đăng ký ngay'
            }
        },
        newsletter_ad: {
            heading: 'Danh sách mới mỗi ngày trong hộp thư đến của bạn',
            text: 'Bạn có muốn nhận thông báo về danh sách mới không? <br> <br> Tạo tài khoản của bạn, cho chúng tôi biết nơi bạn đang tìm địa điểm mới và chúng tôi sẽ gửi cho bạn thông tin cập nhật về các căn hộ mới nhất phù hợp với nhu cầu của bạn mỗi ngày.',
            button: 'Đăng ký ngay'
        },
        results: {
            city_preposition: 'ở',
            provider: 'nhà cung cấp | nhà cung cấp',
            subheading: 'từ {x} {p} khác nhau'
        },
        pagination: {
            pagination_preposition: 'trên',
            listings: 'danh sách'
        },
        userbar: {
            languages: 'ngôn ngữ',
            chose_city: 'Chọn một thành phố',
            city: 'Thành phố'
        },
        footer: {
            copyright: 'Đã đăng ký Bản quyền.',
            cities: 'Các thành phố',
            useful: 'Hữu ích',
            legal: 'Hợp pháp',
            button_open: 'Ngôn ngữ, Điều khoản & Quyền riêng tư',
            button_close: 'Đóng'
            
        },
        filter: {
            missingProvider: 'Nhà cung cấp còn thiếu? Hãy cho chúng tôi biết!',
            toggleMap: 'Hiển thị bản đồ',
            listing_age_heading: 'Ngày danh sách',
            listing_age_label: 'Tất cả {x} được hiển thị.',
            lookback_1: 'Danh sách trong 24 giờ qua',
            lookback_2: 'Danh sách trong 2 ngày qua',
            lookback_3: 'Danh sách trong 3 ngày qua',
            lookback_4: 'Danh sách trong 4 ngày qua',
            lookback_5: 'Danh sách trong 5 ngày qua',
            lookback_6: 'Danh sách trong 6 ngày qua',
            lookback_7: 'Danh sách của tuần trước',
            lookback_14: 'Danh sách trong 2 tuần qua',
            lookback_31: 'Danh sách từ tháng trước',
            lookback_heading: 'End of the list',
            lookback_info: 'These were all {lookback} in {city}.',
            lookback_continue: 'Your search does not need to be over here. Click here to see all {x}.',            
            room: 'phòng | phòng',
            squaremeter: 'mét vuông | mét vuông',
            in_between_label: 'Từ {x} đến {y} {type}',
            room_heading: 'Số phòng',
            room_default_label: 'Hiển thị tất cả các danh sách của bất kỳ số lượng phòng',
            size_heading: 'Kích thước căn hộ',
            size_default_label: 'Hiển thị tất cả các danh sách có kích thước bất kỳ',
            price_heading: 'Giá khoảng',
            price_default_label: 'Hiển thị tất cả các danh sách của bất kỳ giá nào',
            equipment_heading: 'Thiết bị',
            reset: 'Đặt lại bộ lọc',
            features_label: 'Điều hòa, Fi và {x} thêm',
            feature: {
                air_conditioning: 'Điều hòa',
                fan: 'Quạt trần',
                internet: 'Internet',
                wifi: 'Wifi',
                tv: 'TV',
                pool: 'Hồ bơi',
                playground: 'Sân chơi',
                fitness: 'Fitness',
                gym: 'Gym',
                balcony: 'Ban công',
                terrace: 'Sân thượng',
                garden: 'Vườn',
                view: 'Cảnh đẹp',
                washing: 'Máy giặt',
                kitchen: 'Phòng bếp',
                security: 'Bảo vệ',
                service: 'Dịch vụ',
                parking: 'Bãi đậu xe',
                car_access: 'Ra vào ô tô',
                concierge: 'Lễ tân',
                tennis: 'Tennis'
            },
            providers_heading: 'Nhà cung cấp',
            providers_label: 'Danh sách từ {x} nhà cung cấp',
            zero_results_heading: 'Không có kết quả',
            zero_results_text: 'Chúng tôi xin lỗi, nhưng không có kết quả cho tìm kiếm của bạn. Bạn có thể xóa một số bộ lọc hoặc đặt lại bộ lọc hoàn toàn.',
        },
        listing: {
            image: 'hình ảnh | hình ảnh',
            room: 'Phòng | Phòng',
            sqm: 'Mét vuông | Mét vuông',
            rent: 'Thuê / tháng',
            cta: 'Chi tiết',
            more: 'Hơn +{x}'
        },
        details: {
            heading_features: 'Đặc trưng',
            heading_description: 'Miêu tả',
            heading_location: 'Vị trí',
            website_button: 'Đến trang web của nhà cung cấp',
            all_pictures: 'Xem thêm {x} hình ảnh'
        },
        404: {
            heading: 'Không tìm thấy trang',
            text: 'Trang này dường như không tồn tại :('
        },
        account: {
            login: {
                heading: 'Đăng nhập',
                email: 'E-mail',
                password: 'Mật khẩu',
                signin: 'Đăng nhập',
                signup: 'Đăng ký!',
                remember_me: 'Nhớ tôi',
                no_account: 'Không có tài khoản?',
                validation: {
                    no_mail: 'Vui lòng điền vào e-mail của bạn',
                    invalid_mail: 'Vui lòng nhập email hợp lệ',
                    no_password: 'Vui lòng nhập mật khẩu của bạn',
                    invalid_user: 'Tài khoản người dùng có địa chỉ email này không tồn tại',
                    user_not_verified: 'Tài khoản người dùng này chưa được xác minh. Vui lòng kiểm tra email của bạn và theo liên kết được cung cấp bên trong để xác minh tài khoản của bạn.',
                    wrong_password: 'Mật khẩu của bạn có vẻ sai',
                    random_error: 'Ồ không, có gì đó không ổn ở phía chúng tôi. Vui lòng thử lại.',
                }
            },
            register: {
                heading: 'Đăng ký',
                firstname: 'Tên',
                lastname: 'Họ',
                email: 'E-mail',
                password: 'Mật khẩu',
                signup: 'Đăng ký',
                signin: 'Đăng nhập!',
                legal_text: 'Bằng cách nhấp vào Đăng ký, bạn đồng ý với <a target="_blank" href="/terms-of-use">{terms}</a> của chúng tôi và bạn đã đọc <a target="_blank" href="/privacy-policy">{policy}</a> của chúng tôi.',
                have_account: 'Bạn đã có sẵn tài khoản ?',
                success_heading: 'Đăng ký thành công!',
                success_text: 'Đăng ký của bạn đã thành công. Vui lòng kiểm tra email của bạn để xác minh tài khoản của bạn.',
                back: 'Quay trở lại vạch xuất phát',
                validation: {
                    no_firstname: 'Vui lòng điền tên của bạn',
                    no_lastname: 'Vui lòng điền họ của bạn',
                    no_email: 'Vui lòng điền vào e-mail của bạn',
                    invalid_email: 'Có vẻ như email đã nhập không hợp lệ',
                    no_password: 'Vui lòng điền mật khẩu của bạn',
                    short_password: 'Vui lòng nhập mật khẩu có ít nhất 8 ký tự',
                    user_exists: 'Một tài khoản với email này đã tồn tại. Bạn có chắc chắn không muốn đăng nhập?',
                    random_error: 'Ồ không, có gì đó không ổn ở phía chúng tôi. Vui lòng thử lại.',
                }
            },
            verify: {
                loading: 'Xác minh email..',
                success_heading: 'Xác minh thư điện tử thành công!',
                success_text: 'Xin chào {name}, <br> bạn đã xác minh thành công địa chỉ email của bạn <b> {email} </b> và bạn đã sẵn sàng để đi ngay bây giờ. <br> <br> Thời gian để tìm nhà mới của bạn!',
                success_button: 'Đăng nhập!',
                fail_heading: 'Xác minh thư điện tử không thành công!',
                fail_text: 'Xin lỗi, nhưng có vẻ như mã thông báo xác minh được cung cấp đã hết hạn hoặc đã được sử dụng.',
                validation: {
                    token_invalid: 'Mã xác nhận không hợp lệ',
                    token_used: 'Mã thông báo đã được sử dụng. Chắc chắn bạn không chỉ muốn đăng nhập?',
                    random_error: 'Không có gì, có gì không? Vui lòng chỉ có lại.',
                }
            }
        }
    }
};
