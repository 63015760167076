import Vue from 'vue';
import VueRouter from 'vue-router';
import Routers from './router';
import store from './store';
import Util from './libs/util';
import App from './app';
import locale from 'view-design/dist/locale/en-US';
import config from './config';
import i18n from "./i18n/i18n";
import VueAnalytics from 'vue-analytics';
import VueLazyload from 'vue-lazyload';
import VueScrollStop from 'vue-scroll-stop';
import ViewUI from 'view-design';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(ViewUI, { locale });
Vue.use(VueScrollStop);
Vue.use(VueAxios, axios);
Vue.use(VueLazyload, {
    // preLoad: .01,
    loading: '/images/loader.svg'
});

const router = new VueRouter({
    mode: 'history',
    routes: Routers
});

Vue.use(VueAnalytics, {
    id: config.googleAnalyticsKey,
    router
});

Vue.mixin({
    methods: {
        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1)
    }
});

// router.beforeEach((to, from, next) => {
//     // iView.LoadingBar.start();
//     Util.title(to.meta.title);
//     next();
// });

router.afterEach((to, from, next) => {
    // iView.LoadingBar.finish();
    window.scrollTo(0, 0);
});

// Axios Request Interception
Vue.axios.interceptors.request.use(function (config) {
    // set request locale
    config.headers['Accept-Language'] = store.state.locale;

    // set JWT token
    if (store.state.token) {
        config.headers.Authorization = `Bearer ${store.state.token}`;
    }
    
    return config;
});


new Vue({
    el: '#app',
    router: router,
    config: config,
    i18n: i18n,
    store,
    render: h => h(App)
});
