<style scoped lang="less">
    .flag {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 5px;
        top: 2px;
        position: relative;
    }
</style>

<template>
    <img :src="`/images/flags/${countryCode}.svg`" class="flag" />
</template>

<script>
    import LocaleCode from 'locale-code';

    export default {
        props: {
            locale: {
                type: String,
                required: true
            },
        },
        computed: {
            countryCode() {
                return LocaleCode.getCountryCode(this.locale).toLowerCase();
            }
        }
    };
</script>
